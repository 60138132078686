/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useI18next } from 'gatsby-plugin-react-i18next/dist';
// UI lib comoponents;
import { Container } from 'react-grid-system';
// Helpers
import { PartnersEndPoint, baseUploads } from '../../../shared/APIs/index';
// UI lib comoponents

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

// assets
import icon from '../../../images/About/partners/icon-about.svg';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Partners() {
  /* ******************************** Essential variables ******************************* */
  const { language } = useI18next();
  const locale = language === undefined ? 'fr' : language;
  /* ******************************** CONSTANTS ******************************* */
  const { t } = useTranslation();
  const { title, partnerlist } = t('partners', {
    returnObjects: true,
  });
  /* ******************************** HOOKS ******************************* */
  const [partnersList, setPartnersList] = useState();
  // Get all Partners
  async function getAllPartners() {
    const { data } = await axios(PartnersEndPoint(locale));
    setPartnersList(data);
  }
  useEffect(() => {
    getAllPartners();
  }, []);

  /* ******************************** HELPERS ******************************* */

  function ListItems() {
    return partnerlist.map(({ idp, title1 }) => (
      <div key={idp} className="partner-list">
        <div className="partner-list-title">{title1}</div>
        <div className="partner-list-group">
          {partnersList?.data
            ?.filter((partner) => partner.attributes.category === title1)
            .map((filtredPartner) => {
              const {
                attributes: {
                  logo: {
                    data: {
                      attributes: { url },
                    },
                  },
                },
              } = filtredPartner;
              return (
                <div className="partner-list-img">
                  <img
                    className="partner-list-img-item"
                    key=""
                    src={`${baseUploads}${url}`}
                    alt=""
                  />
                </div>
              );
            })}
        </div>
      </div>
    ));
  }
  return (
    <Container justify="center" className="partner" fluid>
      <div className="partner-title">{title}</div>
      <img className="partner-icon" src={icon} alt="icon" />
      <Container>
        <ListItems />
      </Container>
    </Container>
  );
}

Partners.propTypes = {};

Partners.defaultProps = {};

export default Partners;
