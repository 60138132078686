/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function OurMission() {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */

  // State

  // Other hooks like reux or localization ...
  const { t } = useTranslation();
  const { list, title, description1, description2 } = t('mission', {
    returnObjects: true,
  });

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */
  /**
   * Computes the perimeter
   */
  /* ***************************** RENDER HELPERS ***************************** */

  /**
   * Renders the footer
   * @returns {React.Element} The rendered footer
   *
  /* ******************************** RENDERING ******************************* */

  function ListItems() {
    return list.map(({ id, stats, description }) => (
      <div key={id} className="our-mission-stats-box">
        <div className="our-mission-stats">{stats}</div>
        <div>{description}</div>
      </div>
    ));
  }

  return (
    <Container className="our-mission">
      <Row className="title-section">
        <Col lg={6} md={12} className="our-mission-up">
          <div className="our-mission-title">{title}</div>
          <div className="stats-container">
            <ListItems />
          </div>
        </Col>
        <Col lg={6} md={12}>
          <div className="our-mission-description1">{description1}</div>
          <div className="our-mission-description2">{description2}</div>
        </Col>
      </Row>
    </Container>
  );
}

OurMission.propTypes = {};

OurMission.defaultProps = {};

export default OurMission;
