/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
// Lib UI components

// Local UI components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import Slogan from '../page-components/About/Slogan';
import Team from '../page-components/About/Team';
import Partners from '../page-components/About/Partners';
import OurMission from '../page-components/About/OurMission';
// Style

import '../page-styles/about.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */
function About() {
  /* ********************************** HOOKS ********************************* */

  // Localization

  const { t } = useTranslation();
  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title={t('title')} />
      <div className="about-page">
        <Slogan />
        <OurMission />
        {/* <OurHistory /> */}
        <Team />
        <Partners />
      </div>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["About", "Common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default About;
