/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useEffect, useMemo, useState } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import axios from 'axios';

// UI lib comoponents
import { Container } from 'react-grid-system';
import { useTranslation } from 'react-i18next';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
// assets
import { baseUploads, teamMembersEndPoint } from '../../../shared/APIs';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Team() {
  // Localization
  const { t } = useTranslation();
  const { title } = t('team', {
    returnObjects: true,
  });
  const { language } = useI18next();
  const locale = useMemo(
    () => (language === undefined ? 'fr' : language),
    [language],
  );

  // State
  const [members, setMembers] = useState([]);

  useEffect(() => {
    async function getMembers() {
      try {
        const response = await axios.get(teamMembersEndPoint(locale));
        setMembers(
          response?.data?.data?.map((item) => ({
            id: item.id,
            photo: item.attributes.photo.data.attributes.url,
            name: item.attributes.name,
            role: item.attributes.role,
          })),
        );
      } catch (err) {
        console.log(err);
      }
    }
    getMembers();
  }, [locale]);

  function ListItems() {
    return members.map(({ id, photo, name, role }) => (
      <div key={id} className="team-list">
        <div className="team-list-img-container">
          <img
            className="team-list-img"
            src={`${baseUploads}${photo}`}
            alt={name}
          />
        </div>
        <div className="team-list-item">
          <div className="team-list-item-title">{name}</div>
          <div className="team-list-item-role">{role}</div>
        </div>
      </div>
    ));
  }
  if (!(members.length > 0)) return null;
  return (
    <Container>
      <div className="team-title">{title}</div>
      <div className={`team ${members.length > 3 ? '' : 'team--evenly'}`}>
        <ListItems />
      </div>
    </Container>
  );
}

Team.propTypes = {};

Team.defaultProps = {};

export default Team;
