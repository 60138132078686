/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
import Image from '../../../shared/Image';
// assets
import icon from '../../../images/shared-images/iconE.svg';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Slogan() {
  /* ******************************** RENDERING ******************************* */
  // Localization
  const { t } = useTranslation();
  const { title, description } = t('slogan', {
    returnObjects: true,
  });
  return (
    <Container className="slogan" fluid>
      <Row>
        <Col xxl={9} xl={9} lg={9} md={9} sm={12}>
          <div className="imgElife">
            <Image className="sloganE-img" src="elife.jpg" alt="elife" />
          </div>
          <div className="sloganE-desc">
            <div className="sloganE-desc-title">{title}</div>
            <div className="sloganE-desc-information">{description}</div>
          </div>
        </Col>
        <Col xxl={3} xl={3} lg={3} md={3} sm={0} xs={0}>
          <img src={icon} className="sloganE-icon" alt="slogan-icon" />
        </Col>
      </Row>
    </Container>
  );
}

Slogan.propTypes = {};

Slogan.defaultProps = {};

export default Slogan;
